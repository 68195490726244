// 引文追踪模块
import request from '@/utils/request_part copy'
import qs from 'qs'


//  用户注册--确认作者信息
export function confirmUser(data) {
    return request({
        url: '/api/register',
        method: 'post',
        // data,
        data: qs.stringify(data),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        // data: qs.stringify(data),
    })
}

// user_openid 获取用户信息
export function getUser(query) {
    return request({
        url: '/api/have_user',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

// 用户信息获取
export function judge_user(query) {
    return request({
        url: '/api/judge_user',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

//  获取作者基本信息
export function user_mes(data) {
    return request({
        url: '/api/user_mes',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}


//  获取作者文章列表
export function user_article(data) {
    return request({
        url: '/api/user_article',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}

// 用户删除文章
export function delete_article(query) {
    return request({
        url: '/api/delete_article',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

// 已读新增文章
export function new_article(query) {
    return request({
        url: '/api/new_article',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

// 已读新增频次
export function new_cited(query) {
    return request({
        url: '/api/new_cited',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

// 导出文献
export function article_export(query) {
    return request({
        url: '/api/article_export',
        method: 'get',
        headers: {
            'Content-Type': 'application/vnd.ms-excel;charset=UTF-8', //定义请求媒体的类型
        },
        params: query,
        responseType: 'arraybuffer',
        // params: qs.stringify(query),
    })
}

// 生成英文名
export function organ_association(query) {
    return request({
        url: '/api/organ_association',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

//  文献导入
export function article_import(data) {
    return request({
        url: '/api/article_import',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}


//查看所有任务
export function auth_task(query) {
    return request({
        url: '/api/auth_task',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 获取相关信息
export function moreview(query) {
    return request({
        url: '/api/moreview',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
//  任务添加到文章列表
export function auth_task_post(data) {
    return request({
        url: '/api/auth_task',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}

//  删除/添加 重新追踪的结果
export function retracker(data) {
    return request({
        url: '/api/retracker',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}


//查看详情
export function article_detail(query) {
    return request({
        url: '/api/article_detail',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

//查看今日更新
export function article_update(query) {
    return request({
        url: '/api/article_update',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

//重新追踪结果
export function retracker_get(query) {
    return request({
        url: '/api/retracker',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}