<template>
  <div id="app">
    <el-container
      class="container"
      v-if="
        $route.path !== '/' &&
        $route.path !== '/qinli_app' &&
        $route.path !== '/enroll'
      "
    >
      <el-aside>
        <div class="system">
          <div class="organName">{{ mes.org_name }}</div>
          <div class="systemName">图书馆AI科研服务平台</div>
        </div>
        <!-- 菜单栏 -->
        <div class="menuBox">
          <div v-for="(item, index) in menuList" :key="item.id" class="itemBox">
            <el-divider v-if="[7, 11].includes(item.id)"></el-divider>
            <div
              :class="[
                'firstMenu',
                firstMenuCurrent === item.id && 'firstMenu_active',
              ]"
              @click="firstMenuChange(item, index)"
            >
              <div class="leftBox">
                <img
                  v-show="firstMenuCurrent !== item.id"
                  class="icon"
                  :src="require(`./assets/image/${item.id}.png`)"
                  alt=""
                />
                <img
                  v-show="firstMenuCurrent === item.id"
                  class="icon"
                  :src="require(`./assets/image/${item.id}_active.png`)"
                  alt=""
                />
                <el-badge
                  v-if="item.title == '智能咨询' && num_total"
                  :value="num_total"
                  class="item"
                >
                  {{ item.title }}
                </el-badge>
                <span v-if="item.title == '智能咨询' && !num_total">
                  {{ item.title }}
                </span>
                <span v-if="item.title !== '智能咨询'"> {{ item.title }} </span>
              </div>
              <div class="rightBox" v-if="item.subMenuList">
                <i v-show="item.expand" class="el-icon-arrow-down"></i>
                <i v-show="!item.expand" class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="subMenu" v-if="item.subMenuList && item.expand">
              <div
                v-for="subItem in item.subMenuList"
                :key="subItem.id"
                :class="[
                  'subItemBox',
                  subMenuCurrent === subItem.id && 'subMenu_active',
                ]"
                @click="subMenuChange(subItem)"
                :style="
                  !subItem.path
                    ? 'cursor:not-allowed;border: 1px solid #fff;color: #a8aaae;'
                    : ''
                "
              >
                <span
                  :class="[
                    'circle',
                    subMenuCurrent === subItem.id && 'circle_active',
                  ]"
                ></span>

                <el-badge
                  v-if="subItem.title == '资源管理' && num_total"
                  :value="num_total"
                  class="item"
                >
                  {{ subItem.title }}
                </el-badge>
                <span v-if="subItem.title == '资源管理' && !num_total">
                  {{ subItem.title }}
                </span>
                <span v-if="subItem.title !== '资源管理'">
                  {{ subItem.title }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="logo_area">
          <img src="./assets/image/logo.png" />
        </div>
      </el-aside>

      <el-container>
        <el-header height="80px">
          <div class="modelName">{{ modelName }}</div>
          <div class="userInfo" v-if="isRouterAlives">
            <!-- <el-divider></el-divider> -->
            <div class="content">
              <div class="user">
                <el-avatar
                  shape="square"
                  :size="30"
                  :src="mes.avatarUrl"
                ></el-avatar>
                <span class="mobile">{{ phone.replace(/x/g, "*") }}</span>
                <el-tag
                  v-if="mes.id_photo_state === 2"
                  size="small"
                  color="#d14e6c"
                  style="color: #fff"
                  >正式账号</el-tag
                >
                <el-tag v-else size="small" color="#d14e6c" style="color: #fff"
                  >临时用户</el-tag
                >
              </div>
              <div style="line-height: 0">
                <img
                  class="icon"
                  src="./assets/image/logout.png"
                  @click="loginOut"
                />
              </div>
            </div>
            <div v-if="mes.attestation_state === 1" class="hint">
              下次GPS认证开始时间：{{ mes.proveEnd }}
            </div>
            <div v-else class="hint">
              GPS认证剩余时间 {{ mes.gps_remainder }} 天
            </div>
          </div>
        </el-header>

        <el-main
          style="padding: 0 !important; min-width: 1180px; overflow: auto"
        >
          <router-view v-if="isRouterAlives"></router-view>
        </el-main>
      </el-container>
    </el-container>
    <router-view
      v-if="
        ($route.path == '/' ||
          $route.path == '/qinli_app' ||
          $route.path == '/enroll') &&
        isRouterAlives
      "
    ></router-view>
    <!-- 注册信息 -->
    <el-dialog
      title="注册信息"
      :visible.sync="dialogFormVisible"
      :width="'500px'"
      :append-to-body="true"
    >
      <p class="tit_message">
        根据注册信息，我们获取到您的发文信息如下，我们会根据获取到的信息追踪相应的文献，请您核对发文名称是否正确。
      </p>
      <el-form :model="form">
        <el-form-item label="中文发文名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="英文发文名称" :label-width="formLabelWidth">
          <el-input v-model="form.en_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属机构" :label-width="formLabelWidth">
          <el-input disabled v-model="form.code" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="enroll">开始追踪</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { organinfo } from "@/Api/journals";
import { unreadNumber } from "@/Api/manage";
import { personalMessage, fetchMessage } from "@/Api/userMessage";
import { eventBus } from "./eventBus";
import {
  getUser,
  judge_user,
  confirmUser,
  organ_association,
} from "@/Api/unquote";
export default {
  name: "App",
  data() {
    const allMenuList = [
      { id: 1, title: "首页", path: "home", name: "home" },
      {
        id: 2,
        title: "智能咨询",
        expand: false,
        name: "Search",
        subMenuList: [
          // { id: 21, title: "咨询记录", path: "/search?webPage=1", parentId: 2 },
          { id: 21, title: "文献查找", path: "/search?webPage=1", parentId: 2 },
          //{ id: 22, title: "文献查找", path: "/search?webPage=2", parentId: 2 },
          { id: 22, title: "AI问答", path: "/search?webPage=3", parentId: 2 },
          { id: 23, title: "资源管理", path: "/search?webPage=2", parentId: 2 },
          { id: 24, title: "更多工具", path: "/search?webPage=4", parentId: 2 },
        ],
      },
      {
        id: 3,
        title: "查重与校对",
        expand: false,
        name: "Dissertation",
        subMenuList: [
          {
            id: 31,
            title: "论文查重",
            path: "/dissertation?webPage=1",
            parentId: 3,
          },
          {
            id: 32,
            title: "语法校对",
            path: "/dissertation?webPage=2",
            parentId: 3,
          },
          // { id: 33, title: '语法校对 - Writeful', path:'/dissertation?webPage=4', parentId: 3 },
          {
            id: 34,
            title: "查看报告",
            path: "/dissertation?webPage=3",
            parentId: 3,
          },
        ],
      },
      {
        id: 4,
        title: "文档翻译",
        expand: false,
        name: "Translation",
        subMenuList: [
          {
            id: 41,
            title: "文件上传",
            path: "/translation?webPage=1",
            parentId: 4,
          },
          {
            id: 42,
            title: "查看报告",
            path: "/translation?webPage=2",
            parentId: 4,
          },
        ],
      },
      {
        id: 5,
        title: "学术推送",
        expand: false,
        name: "Academic",
        subMenuList: [
          {
            id: 51,
            title: "中文期刊推荐",
            path: "/academic?webPage=1",
            parentId: 5,
          },
          {
            id: 52,
            title: "英文期刊推荐",
            path: "/academic?webPage=2",
            parentId: 5,
          },
          // { id: 53, title: '机构订阅', path:'/academic?webPage=4', parentId: 5 },
          {
            id: 54,
            title: "个人中心",
            path: "/academic?webPage=3",
            parentId: 5,
          },
        ],
      },
      {
        id: 6,
        title: "引文追踪",
        expand: false,
        name: "Citation",
        subMenuList: [
          {
            id: 61,
            title: "中文文献追踪",
            path: "/citation?webPage=1",
            parentId: 6,
          },
          {
            id: 62,
            title: "英文文献追踪",
            path: "/citation?webPage=2",
            parentId: 6,
          },
          {
            id: 63,
            title: "任务列表",
            path: "/citation?webPage=3",
            parentId: 6,
          },
          {
            id: 64,
            title: "个人中心",
            path: "/citation?webPage=4",
            parentId: 6,
          },
        ],
      },
      {
        id: 7,
        title: "AI馆员",
        type: "external",
        path: "https://chatanswer.newacademic.net/",
        name: "ai",
      },
      {
        id: 8,
        title: "新知发现",
        type: "external",
        path: "https://scholar.newacademic.net/#/home",
        name: "knowledge",
      },
      {
        id: 9,
        title: "学位论文发现",
        type: "external",
        path: "https://dissertation.newacademic.net/#/index",
        name: "wdl",
      },
      {
        id: 10,
        title: "期刊发现",
        type: "external",
        path: "https://search.newacademic.net/#/index",
        name: "journal",
      },
      // { id: 11, title: '历史' },
      {
        id: 12,
        title: "我的",
        expand: false,
        name: "Personal",
        subMenuList: [
          {
            id: 121,
            title: "帮助中心",
            path: "/personal?webPage=1",
            parentId: 12,
          },
          {
            id: 122,
            title: "反馈问题",
            path: "/personal?webPage=2",
            parentId: 12,
          },
          {
            id: 123,
            title: "消息中心",
            path: "/personal?webPage=3",
            parentId: 12,
          },
        ],
      },
    ];
    return {
      firstMenuCurrent: 1,
      subMenuCurrent: null,
      allMenuList,

      gps_remainder: "",
      gps_term: "",
      is_close: false,
      gpsshow: false,
      activeIndex: "Search",
      name: "",
      email: "",
      phone: "",
      total: null,
      mes: {
        push_extend: true,
        is_hidden: true,
        track_extend: true,
      },
      dialogFormVisible: false,
      formLabelWidth: "120px",
      form: {
        name: "",
        en_name: "",
        code: "",
      },
      enName: "",
      showInfo: false,
      banned_show: false,
      organ_product: [],
      show_: true,
      openIndex: null,
      isRouterAlives: true,
      logoutDisabled: false,
      num_total: 0,
      intervalId: null,
      disable_group_s: [
        "39053605528@chatroom",
        "56530986784@chatroom",
        "52571019643@chatroom",
        "38696601715@chatroom",
        "35034516726@chatroom",
        "38802985139@chatroom",
        "39227070897@chatroom",
        "20679458060@chatroom",
        "34517104569@chatroom",
        "34903209947@chatroom",
        "34496301855@chatroom",
        "44071109340@chatroom",
        "44558909279@chatroom",
        "49983741456@chatroom",
        // "44094707619@chatroom",
        "45496379845@chatroom",
        "44552503109@chatroom",
        "44910989359@chatroom",
        "39244616932@chatroom",
        "39304327028@chatroom",
        "22582410266@chatroom",
        "34462242436@chatroom",
        "38678517676@chatroom",
        "22018629545@chatroom",
        "23843205420@chatroom",
        "25639799054@chatroom",
        "25206716703@chatroom",
        // "23887022323@chatroom",
        "25094686337@chatroom",
        "25682179786@chatroom",
        "23524403013@chatroom",
        "23525613804@chatroom",
        "24166403217@chatroom",
        "22491014284@chatroom",
        "23108123075@chatroom",
        "24252511613@chatroom",
        "25107783359@chatroom",
        "20874107163@chatroom",
        "22337408040@chatroom",
        "22604806822@chatroom",
        // "24986167058@chatroom",
        "17414825312@chatroom",
        // "25295060073@chatroom",
        "23992692252@chatroom",
        "20231297349@chatroom",
        "20553498375@chatroom",
        "14029975295@chatroom",
        "20126095437@chatroom",
        "17227369353@chatroom",
      ],
    };
  },
  provide() {
    return {
      reloads: this.reloads,
    };
  },
  computed: {
    modelName() {
      if (this.subMenuCurrent) {
        let firstMenuList = this.allMenuList.find(
          (item) => item.id === this.firstMenuCurrent
        );
        return firstMenuList.subMenuList.find(
          (item) => item.id === this.subMenuCurrent
        ).title;
      } else {
        return this.allMenuList.find(
          (item) => item.id === this.firstMenuCurrent
        ).title;
      }
    },
    menuList() {
      return this.allMenuList.filter((item) => {
        if (
          [3, 4, 5, 6, 7, 8, 9, 10].includes(item.id) &&
          this.disable_group_s.includes(this.mes.belong_id)
        )
          return;
        if ([1, 2, 3, 4, 7, 11, 12].includes(item.id)) return item;
        if (item.id === 5 && (this.mes.push_extend || this.mes.is_hidden)) {
          return item;
        }
        if (item.id === 6 && (this.mes.track_extend || this.mes.is_hidden)) {
          return item;
        }
        if (item.id === 8) {
          let flag = this.organ_product.find((v) => v.id === 25);
          if (flag) return item;
        }
        if (item.id === 9) {
          let flag = this.organ_product.find((v) => v.id === 14);
          if (flag) return item;
        }
        if (item.id === 10) {
          let flag = this.organ_product.find((v) => v.id === 1);
          if (flag) return item;
        }
      });
    },
  },
  created() {
    if (sessionStorage.getItem("mes")) {
      this.mes = JSON.parse(sessionStorage.getItem("mes"));
    }
    this.message();
    // 学术推送 - 机构订阅
    if (
      localStorage.getItem("subscribeOr") &&
      localStorage.getItem("subscribeOr") == "1"
    ) {
      // 在this.allMenuList[4].subMenuList第三个位置插入一个元素
      this.allMenuList[4].subMenuList.splice(2, 0, {
        id: 53,
        title: "机构订阅",
        path: "/academic?webPage=4",
        parentId: 5,
      });
    }
    // this.unreads()
    this.intervalId = setInterval(this.unreads, 60000);
    eventBus.$on("updateNum", this.handleUpdateNum);
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to.name === "Login") {
          return (this.mes = {
            push_extend: true,
            is_hidden: true,
            track_extend: true,
          });
        }
        if (to.name === "home") {
          if (!this.mes.id_photo_state) {
            return this.message();
          }
        }
        let { fullPath, name } = to;
        for (let i = 0; i < this.allMenuList.length; i++) {
          let item = this.allMenuList[i];
          if (item.name === name) {
            this.firstMenuCurrent = item.id;
            item.expand = true;
            if (item.subMenuList) {
              for (let j = 0; j < item.subMenuList.length; j++) {
                let subItem = item.subMenuList[j];
                if (
                  fullPath === subItem.path ||
                  fullPath.includes(subItem.path)
                ) {
                  this.subMenuCurrent = subItem.id;
                  return;
                }
              }
            } else {
              this.subMenuCurrent = null;
              return;
            }
          } else {
            if (i === this.allMenuList.length - 1) {
              let menuCurrent = JSON.parse(
                sessionStorage.getItem("menuCurrent")
              );
              if (menuCurrent) {
                this.firstMenuCurrent = menuCurrent.firstMenuCurrent;
                this.subMenuCurrent = menuCurrent.subMenuCurrent;
                let list = this.allMenuList.find(
                  (item) => item.id === this.firstMenuCurrent
                );
                if (list.subMenuList) {
                  list.expand = true;
                }
              } else {
                this.firstMenuCurrent = 1;
                this.subMenuCurrent = null;
              }
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleUpdateNum(num) {
      this.num_total = num;
    },
    refreshData() {
      this.unreads(); // 获取当前时间并更新
    },
    stopRefreshing() {
      clearInterval(this.intervalId); // 停止定时器
      this.intervalId = null; // 清空定时器ID
    },
    unreads() {
      unreadNumber()
        .then((res) => {
          // console.log(res);
          if (res.data.data.num > 0) {
            this.num_total = res.data.data.num;
            console.log(this.num_total);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 退出登录
    loginOut() {
      this.logoutDisabled = true;
      this.$confirm("确认退出账号？")
        .then((_) => {
          this.logoutDisabled = false;
          localStorage.clear();
          sessionStorage.clear();
          this.$router.push({
            name: "Login",
          });
        })
        .catch((_) => {
          setTimeout(() => {
            this.logoutDisabled = false;
          }, 500);
        });
    },
    // 一级菜单切换
    async firstMenuChange(item, index) {
      if (item.id === 7) {
        return this.$message({
          message: "极速升级，即将呈现，敬请期待！",
          type: "warning",
        });
      }
      if (item.subMenuList) {
        // 一级菜单展开
        // Academic
        if (item.name && item.name === "Academic") {
          if (this.mes.push_extend == false) {
            this.$message({
              message:
                "暂无该功能的使用权限，快去联系所属机构负责人进行开通体验吧！",
              type: "info",
            });
          } else {
            let open_code = {
              open_id: localStorage.getItem("code"),
              organ_name: localStorage.getItem("org_ode"),
            };
            organinfo(open_code)
              .then((res) => {
                localStorage.setItem("organ_info", res.data.data);
                item.expand = !item.expand;
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else if (item.name && item.name === "Citation") {
          if (this.mes.track_extend) {
            let user_citation = {
              user_openid: localStorage.getItem("code"),
              institution_name: localStorage.getItem("org_name"),
            };
            const res = await getUser(user_citation);
            if (res.data.have_user !== 1) {
              this.openDialog(index);
            } else {
              let openCode = {
                user_openid: localStorage.getItem("code"),
              };
              judge_user(openCode)
                .then((res) => {
                  if (res.data.user_mes.length > 0) {
                    localStorage.setItem(
                      "en_name",
                      res.data.user_mes[0].en_user_name
                    );
                    item.expand = !item.expand;
                  } else {
                    this.openDialog(index);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            this.$message({
              message:
                "暂无该功能的使用权限，快去联系所属机构负责人进行开通体验吧！",
              type: "info",
            });
          }
        } else {
          item.expand = !item.expand;
        }
      } else if (item.type === "external") {
        // 外部链接跳转
        let id_photo_state = sessionStorage.getItem("id_photo_state")
          ? sessionStorage.getItem("id_photo_state")
          : "2";
        let unionid = sessionStorage.getItem("unionid");
        let org_ode = localStorage.getItem("org_ode");
        if (id_photo_state == "2") {
          let url = item.path + "?type=ql";
          if (item.name === "knowledge") {
            url =
              url +
              "&user_id=" +
              unionid +
              "&organ_code=" +
              org_ode +
              "&source=ql";
          } else if (item.name === "wdl") {
            url = url + "&unionid=" + unionid + "&org_ode=" + org_ode;
          } else if (item.name === "journal") {
            url = url + "&user_id=" + unionid + "&organ_code=" + org_ode;
          }
          window.open(url, "_blank");
        } else {
          this.open_confirm();
        }
      } else {
        // 二级目录
        if (item.id === this.firstMenuCurrent) return;
        this.firstMenuCurrent = item.id;
        this.subMenuCurrent = null;
        sessionStorage.setItem(
          "menuCurrent",
          JSON.stringify({
            firstMenuCurrent: this.firstMenuCurrent,
            subMenuCurrent: this.subMenuCurrent,
          })
        );
        this.$router.push({ path: item.path });
      }
    },
    // 二级菜单切换
    subMenuChange(subItem) {
      console.log(subItem);
      if (!subItem.path) {
        return;
      }

      if (subItem.id === this.subMenuCurrent) return;
      this.subMenuCurrent = subItem.id;
      this.firstMenuCurrent = subItem.parentId;
      sessionStorage.setItem(
        "menuCurrent",
        JSON.stringify({
          firstMenuCurrent: this.firstMenuCurrent,
          subMenuCurrent: this.subMenuCurrent,
        })
      );
      this.$router.push({ path: subItem.path });
    },
    message() {
      if (localStorage.getItem("token")) {
        personalMessage()
          .then((res) => {
            sessionStorage.setItem("mes", JSON.stringify(res.data.data));
            this.mes = res.data.data;
            this.name = res.data.data.name;
            this.email = res.data.data.email;
            this.phone = res.data.data.phone;
            sessionStorage.setItem(
              "id_photo_state",
              res.data.data.id_photo_state
            );
            sessionStorage.setItem("headshot", res.data.data.avatarUrl);
            sessionStorage.setItem("unionid", res.data.data.unionid);
            localStorage.setItem(
              "organ_product",
              JSON.stringify(res.data.data.organ_product)
            );
            this.organ_product = res.data.data.organ_product || [];
            localStorage.setItem("code", res.data.data.code);
            localStorage.setItem(
              "selected_journals",
              res.data.data.selected_journals
            );
            // localStorage.setItem("email", res.data.data.email);
            localStorage.setItem("org_ode", res.data.data.org_code);
            localStorage.setItem("org_name", res.data.data.org_name);
            localStorage.setItem(
              "attestation_state",
              res.data.data.attestation_state
            );
            // res.data.data.attestation_state = 7;
            //  this.gpsshow = true;
            if (
              res.data.data.attestation_state == 3 ||
              res.data.data.attestation_state == 2
            ) {
              this.gpsshow = true;
              this.gps_remainder = res.data.data.gps_remainder;
              this.gps_term = res.data.data.gps_term;
              if (res.data.data.attestation_state == 3) {
                this.is_close = false;
              } else {
                this.is_close = true;
              }
            } else if (res.data.data.attestation_state == 7) {
              this.showInfo = true;
            }
            if (res.data.data.writful_extend) {
              localStorage.setItem("writful_permissions", 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });

        fetchMessage()
          .then((res) => {
            // console.log(res);
            let record = 0;
            if (res.data.data.length > 0) {
              res.data.data.forEach((item) => {
                if (item.record_id == null) {
                  ++record;
                }
              });
            }
            // console.log(record)
            if (record > 0) {
              this.total = record;
            }

            this.$emit("getTreeData", record);
            // this.$emit("newTotal", record);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 引文追踪
    openDialog(index) {
      let chinese_name = {
        keyword: localStorage.getItem("name"),
      };
      this.openIndex = index;
      organ_association(chinese_name)
        .then((res) => {
          this.enName = res.data.data;
          this.form = {
            name: localStorage.getItem("name"),
            en_name: res.data.data,
            code: localStorage.getItem("org_name"),
          };
          this.dialogFormVisible = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    enroll() {
      let register_data = {
        ch_user_name: localStorage.getItem("name"),
        en_user_name: this.enName,
        institution_name: localStorage.getItem("org_name"),
        user_openid: localStorage.getItem("code"),
        institution_code: localStorage.getItem("org_ode"),
      };
      confirmUser(register_data)
        .then((res) => {
          let openCode = {
            user_openid: localStorage.getItem("code"),
          };
          judge_user(openCode)
            .then((res) => {
              localStorage.setItem(
                "en_name",
                res.data.user_mes[0].en_user_name
              );
              this.allMenuList[this.openIndex].expand = true;
              this.dialogFormVisible = false;
              this.openIndex = null;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 外链跳转无权限提示
    open_confirm() {
      this.$confirm(
        "该功能需进行身份验证，请前往微信小程序完善您的证件信息。感谢您的配合与理解！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showCancelButton: false,
          // showConfirmButton: false,
        }
      )
        .then(() => {})
        .catch(() => {});
    },

    reloads() {
      this.isRouterAlives = false;
      this.$nextTick(function () {
        this.isRouterAlives = true;
      });
    },
  },
};
</script>
<style lang="less">
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.el-aside {
  width: 260px !important;
  height: 100vh;
  background: #ffffff;

  .system {
    height: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 0 24px;
    box-sizing: border-box;

    .organName {
      font-size: 24px;
      color: #000;
      font-style: initial;
      font-family: DingTalk-JinBuTi;
    }

    .systemName {
      font-size: 14px;
      color: #e2426b;
      font-style: initial;
      font-family: DingTalk-JinBuTi;
    }
  }

  .logo_area {
    position: fixed;
    width: 252px;
    // left: 24px;
    bottom: 15px;
    // border-radius: 10px;
    display: flex;
    justify-content: center;
  }

  .menuBox {
    padding: 24px 24px 0 24px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);
    overflow-y: auto;
    gap: 8px;

    .itemBox {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #6b6e73;
      font-size: 16px;
      font-weight: 500;

      .el-divider {
        margin: 4px 0;
      }

      .firstMenu:hover {
        background-color: #ffe9ef;
      }

      .firstMenu_active {
        background-color: #ffe9ef;
        color: #e2426b;
      }

      .firstMenu {
        height: 44px;
        line-height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        border-radius: 8px;

        .leftBox {
          display: flex;
          align-items: center;
          gap: 8px;

          .icon {
            width: 24px;
            height: 24px;
            vertical-align: middle;
          }
        }
      }

      .subMenu {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .subItemBox:hover {
          border: 1px solid #e2426b;
        }

        .subMenu_active {
          color: #e2426b;
          border: 1px solid #e2426b;
        }

        .subItemBox {
          display: flex;
          align-items: center;
          gap: 8px;
          padding-left: 36px;
          box-sizing: border-box;
          border-radius: 8px;
          height: 42px;
          line-height: 42px;

          .circle {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #6b6e73;
            display: inline-block;
          }

          .circle_active {
            background-color: #e2426b;
          }
        }
      }
    }

    .el-menu {
      border: none;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .el-menu-item:hover,
      .el-submenu__title:hover {
        background-color: #ffe9ef;
      }

      ::v-deep .el-submenu {
        border-radius: 8px;
      }

      .el-menu-item,
      .el-submenu__title {
        height: 44px;
        line-height: 44px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px;
        gap: 8px;

        .icon {
          width: 24px;
          height: 24px;
          vertical-align: middle;
        }

        span {
          line-height: 1px;
        }

        .circle {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #6b6e73;
        }

        .active {
          background-color: #e2426b;
        }
      }

      .menuActive {
        background-color: #ffe9ef;
      }

      .subMenuActive {
        border: 1px solid #e2426b;
        background-color: #fff;
      }
    }
  }
}

.el-header {
  padding: 0 24px !important;
  line-height: 80px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .modelName {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    font-family: "PingFang SC";
  }

  .userInfo {
    width: max-content;

    .el-divider {
      margin: 8px 0;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;

      .user {
        display: flex;
        align-items: center;
        gap: 8px;

        .mobile {
          font-size: 16px;
          font-weight: 700;
        }

        .el-tag {
          color: #262729;
          font-size: 10px;
        }
      }

      .icon {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .hint {
      margin-top: 8px;
      font-size: 12px;
      color: #a8aaae;
      line-height: 16px;
    }
  }

  img {
    vertical-align: middle;
  }
}

.tit_message {
  line-height: 1.5;
  margin-bottom: 20px;
}
.el-badge__content.is-fixed {
  top: 10px !important;
}
</style>
