// 学术推送期刊模块
import request from '@/utils/request_part'
import qs from 'qs'
// 学科列表
export function discipline_list(query) {
    return request({
        url: '/api/subject_recom',
        method: 'get',
        params: query,
        headers: {
            "Content-Typupdate_articlee": "application/x-www-form-urlencoded",
        },
        // params: qs.stringify(query),

    })
}
// 期刊列表
export function journal_list(query) {
    return request({
        url: '/api/journal_list',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 期刊列表
export function essay_list(query) {
    return request({
        url: '/api/searchArticle',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 期刊学科
export function journal_discipline(query) {
    return request({
        url: '/api/journal_subject',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 期刊详情
export function journal_detail(query) {
    return request({
        url: '/api/journal_detail',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 期刊卷期
export function volume_period(query) {
    return request({
        url: '/api/journal_issue',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 期刊文章列表
export function article_list(query) {
    return request({
        url: '/api/journal_article',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 文章详情
export function article_detail(query) {
    return request({
        url: '/api/article_details',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 更多往期
export function more_past(query) {
    return request({
        url: '/api/more_issue',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 存储pdf链接
export function pdf_link(data) {
    return request({
        url: '/api/pdf_link',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}
// 已读最新期刊
export function reads(data) {
    return request({
        url: '/api/reads',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}


// 订阅列表
export function subscribe_list(query) {
    return request({
        url: '/api/subscribe_list',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 收藏列表
export function collect_list(query) {
    return request({
        url: '/api/collect_list',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 个人订阅期刊更新数量
export function update_article(query) {
    return request({
        url: '/api/update_article',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 订阅学科期刊
export function subscribe_subject(data) {
    return request({
        url: '/api/subscribe',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}
// 取消订阅学科期刊
export function cancel_subscribe(data) {
    return request({
        url: '/api/cancel_subscribe',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}
// 收藏期刊文章
export function collect(data) {
    return request({
        url: '/api/collect',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}
// 取消收藏期刊文章
export function cancel_collect(data) {
    return request({
        url: '/api/cancel_collect',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}
// 文章详情
export function article_details(query) {
    return request({
        url: '/api/article_details',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 一键已读
export function been_read(query) {
    return request({
        url: '/api/been_read',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 机构信息更新
export function organinfo(data) {
    return request({
        url: '/api/organinfo',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}

// 原文传递
export function emailpass(data) {
    return request({
        url: '/api/emailpass',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // 'organ-info':localStorage.getItem('organ_info'),
        },
        data: qs.stringify(data),
    })
}
// 邮件列表
export function email_list(query) {
    return request({
        url: '/api/email_list',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // 'organ-info':localStorage.getItem('organ_info'),
        },
        params: query,
        // params: qs.stringify(query),
    })
}
// 全文下载统计
export function downloadpdf(query) {
    return request({
        url: '/api/downloadpdf',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // 'organ-info':localStorage.getItem('organ_info'),
        },
        params: query,
        // params: qs.stringify(query),
    })
}

