import axios from 'axios'
// import store from "../router/index"
import store from '@/store'
import VueRouter from 'vue-router'
import {
    Message
} from 'element-ui'
import router from '../router/index'


// 创建axios 实例
const service = axios.create({
    // baseURL: "http://192.168.66.18:8000",
    // baseURL: "https://track.newacademic.net",
    baseURL: 'https://push.newacademic.net',
    // baseURL: 'http://192.168.66.18:8000',
    // baseURL:"https://ql.dic.cool/push",
    timeout: 50000 // 请求超时时间
})

service.interceptors.request.use((config) => {
    // 动态设置请求头
    config.headers.common = {
        // Authorization: 'Bearer ' + store.getCookie('token')
        token: localStorage.getItem('token'),
        'organ-info': localStorage.getItem('organ_info'),
        // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E'
    }

    // if (config.method === 'post') {
    //     config.data = {
    //         ...config.data,
    //         t: Date.parse(new Date())
    //     }
    // } else if (config.method === 'get') {
    //     config.params = {
    //         ...config.params,
    //         t: Date.parse(new Date())
    //     }
    // }
    return config
}, (err) => {
    return Promise.reject(err)
})

service.interceptors.response.use((res) => {
    // console.log(res)
    if (res.data.code == 200) {
        // 请求成功
        return res
    } else {
        return res
        Message.error({
            type: 'error',
            message: res.data.message,
        });
    }
    //   const response = res.data
    //   if (response.error_code == 0) {
    //     // 请求成功
    //     return res
    //   } else if (response.error_code == 400) {
    //     // token为空、失效 跳转登录页
    //     localStorage.clear();
    //     // store.clearToken('token')
    //     // store.clearToken('email')
    //     // store.clearToken('faculty')
    //     // store.clearToken('id')
    //     // store.clearToken('name')
    //     // store.clearToken('phone')
    //     // store.clearToken('status')
    //     router.push({
    //       name: "Login",
    //     });
    //   } else {
    //     // 提示错误信息
    //     if (response.msg != '今日已提交过此需求') {
    //       Message.error({
    //         type: 'error',
    //         message: response.msg
    //       });
    //     }

    //     return Promise.reject(new Error(response.msg || 'Error'))
    //   }
}, (err) => {
    Message.error({
        type: '请求失败！请稍后再试',
        message: response.msg
    });
    return Promise.reject(err)
})

export default service