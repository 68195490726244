// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
export default{
  setToken(token){
      window.localStorage.setItem('token',JSON.stringify(token))
  },
  getToken(token){
      return JSON.parse(window.localStorage.getItem(token) || '{}')
  },
  clearToken(token){
      window.localStorage.removeItem(token)
  },
  setCookie(cname, cvalue, exdays) {  
      var d = new Date();  
      d.setTime(d.getTime() + (exdays*24*60*60*1000));  
      var expires = "expires="+d.toUTCString();  
      document.cookie = cname + "=" + cvalue + "; " + expires;  
  },
  getCookie(cname) {
      var name = cname + "=";  
      var ca = document.cookie.split(';');  
      for(var i=0; i<ca.length; i++) {  
          var c = ca[i];  
          while (c.charAt(0)==' ') c = c.substring(1);  
          if (c.indexOf(name) != -1) return c.substring(name.length, c.length);  
      }  
      return "";  
  },
  clearCookie(name){
      this.setCookie(name, "", -1)
  }

}
